@import url("https://cdn.syncfusion.com/ej2/material.css");

.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}
.nav-item,
.navbar {
  z-index: 10000;
}
@media screen and (max-width: 800px) {
  .sidebar {
    z-index: 10000000;
  }
}

.e-dlg-center-center,
.e-quick-popup-wrapper.e-device {
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color-picker style  */

#preview {
  background: transparent
    url("https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png")
    no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border: none;
}

/* Autocomplete styles */
.e-ddl.e-input-group input.e-input::placeholder {
  /* placeholder text */
  color: gray;
  font-size: 25px;
  margin: 0px;
  padding: 0px;
}

.e-ddl.e-input-group input.e-input {
  /* input text */
  text-align: center;
  color: black;
  font-size: 25px;
}

.e-ddl.e-input-group.e-control-wrapper.e-input-focus::before,
.e-ddl.e-input-group.e-control-wrapper.e-input-focus::after {
  /* dropdown focus underline */
  background: red;
}

.e-input-group.e-control-wrapper.e-ddl.e-lib.e-keyboard {
  width: 500px;
}

/* .e-dropdownbase .e-list-item,
.e-dropdownbase .e-list-item.e-item-focus {
  dropdown
  background-color: white;
  color: black;
  min-height: 100px;
} */

/* Hide spinner for inputs: Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spinner for inputs: Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Style Tooltip Content */
.e-tooltip-wrap .e-tip-content {
  color: white;
  font-size: 18px;
  text-align: center;
}
